<template>
<div>
  <div v-if="!isLoading" class="row mb-5 gy-2">
    <div class="col-12">
      <h5 class="mb-3">Product details</h5>
      <div v-if="product">
        <div class="card card-height-100">
          <div class="card-body">
            <div class="row gy-4 justify-content-between align-items-start">
              <div class="col-10">
                <h5 class="mb-0"> {{ product.title }}</h5>
              </div>
              <div class="col-2 text-end">
                <div class="dropdown no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li> 
                      <button @click.prevent="popupModalShow = true" class="dropdown-item"
                       type="button" > Update product </button>
                    </li>
                    <li> 
                      <button @click.prevent="deleteItem()" class="dropdown-item"
                       type="button" > Delete product </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-4 d-sm-flex align-items-center justify-content-start">
                  <div class="mb-2 mb-sm-0 me-sm-3">
                    <img v-if="product.image" style="width:3rem;" :src="absoluteUrl(product.image)" />
                    <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                      <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{product.title.charAt(0).toUpperCase()}}
                      </div>
                    </div>
                  </div> 
                  <div class="">
                    <div class="mb-1"> Price: <strong> ${{product.price}} </strong></div>
                    <div> Quantity in Stock: <strong>  {{product.stock_quantity}} </strong> </div>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="mb-2 me-3" v-if="product.brand"> <strong>Make: </strong> {{product.brand.title}}</div>
                  <div class="" v-if="product.category"> <strong>Category: </strong> {{product.category.title}}</div>
                </div>
                <div class="">
                  <h6 class="mb-2">Product attributes</h6>
                  <div> <strong>Color:</strong> {{ product.color }} </div>
                  <div> <strong>Condition:</strong> {{ $filters.textFromSlug(product.condition) }} </div>
                  <div> <strong>Storage:</strong> {{ product.storage }}  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-height-100">
          <div class="card-body">
            <div class="row gy-4 justify-content-between align-items-start">
              <div class="col-12">
                <h5 class="mb-2">Meta title</h5>
                <div>{{ product.meta_title }}</div>
              </div>
              <div class="col-12">
                <h5 class="mb-2">Meta keywords</h5>
                <div>{{ product.meta_keys }}</div>
              </div>
              <div class="col-12">
                <h5 class="mb-2">Meta descriptions</h5>
                <div v-html="preText(product.meta_desc)"></div>
              </div>
              <div class="col-12">
                <hr class="my-2" />
              </div>
              <div class="col-12">
                <h5 class="mb-2">Product descriptions</h5>
                <div v-html="product.description"></div>
              </div>
              <div class="col-12 text-end">
                <button @click.prevent="popupDescriptionShow = true" class="btn btn-sm btn-primary"
                 type="button">Manage Description</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-height-100">
          <div class="card-body">
            <product-files :product="product"
              @updatedFiles="updateFiles($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal size="lg" v-model="popupModalShow" body-class="loading-viewport" hide-footer centered
    title="Update product"
    title-class="fs-6" @hide="initializeAll()">
    <product-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editMode="true" :editItem="product" @updateItem="updateItem($event)" />
  </b-modal>  
  <b-modal size="lg" v-model="popupDescriptionShow" body-class="loading-viewport" hide-footer centered
    title="Update product description"
    title-class="fs-6" @hide="initializeAll()">
    <product-description-form v-if="popupDescriptionShow" @closeMe="initializeAll()"
    :editItem="product" @updateItem="updateItem($event)" />
  </b-modal>                                                                      
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import ProductForm from '@/components/forms/ProductForm.vue'
import ProductDescriptionForm from '@/components/forms/ProductDescriptionForm.vue'
import ProductFiles from './ProductFiles.vue'

export default {
  components: { 
    IsLoading,
    ProductForm,
    ProductDescriptionForm,
    ProductFiles
  },
  data(){
    return{
      isLoading: false,
      product: null,
      popupModalShow: false,
      popupDescriptionShow: false,
    }
  },
  methods:{
    updateItem(item){
      Object.assign(this.product, item)
    },
    updateFiles(files){
      this.product.files = [...files]
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/products/${this.$route.params.productId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.product = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem() {
      this.confirmDelete({
        text: "You are about to delete a product",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/products/${this.product.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/products'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
      this.popupDescriptionShow = false
    },
  },
  created(){
    this.fetchItem()
  }
}
</script>

<style>

</style>
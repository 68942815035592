<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row gy-3">
            <div class="col-md-12">
                <label for="inputTitle">
                    Title <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.title"
                    :class="{ 'is-invalid': v$.data.title.$error }"
                    id="inputTitle" name="title" class="form-control"
                    placeholder="Enter title" />
                <div v-for="(item, index) in v$.data.title.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-label">
                    Slug <span class="text-danger">*</span>
                </label>
                <input type="text" v-model="data.slug" class="form-control"
                    name="slug" :class="{ 'is-invalid': v$.data.slug.$error }" placeholder="Enter slug" />
                <div v-for="(item, index) in v$.data.slug.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-md-6">
                <label for="inputPrice">
                    Price <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.price" class="form-control"
                name="price" id="inputPrice" :class="{ 'is-invalid': v$.data.price.$error }" placeholder="Enter Price" />
                <div v-for="(item, index) in v$.data.price.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Category</label>
                <select @change="categoryChange($event)" class="form-select" v-model="data.category_id"
                name="category_id" placeholder="--select type--">
                    <option disabled value="">--select type--</option>
                    <option v-for="category in categories" :key="category.id"
                        :value="category.id">{{category.title}}</option>
                </select>
                <div v-for="(item, index) in v$.data.category_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Make</label>
                <select class="form-select" v-model="data.brand_id"
                :class="{ 'is-invalid': v$.data.brand_id.$error }"
                name="type" placeholder="--select make--">
                    <option disabled value="">--select make--</option>
                    <option v-for="brand in brands" :value="brand.id" 
                    :key="brand.id">{{brand.title}}</option>
                </select>
                <div v-for="(item, index) in v$.data.brand_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Storage <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="data.storage"
                    name="storage" placeholder="--select--">
                    <option disabled value="">--select--</option>
                    <option v-for="storage in storageOptions" :key="storage.id"
                        :value="storage.value">{{storage.text}}</option>
                </select>
                <div v-for="(item, index) in v$.data.storage.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Condition <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="data.condition"
                name="condition" placeholder="--select--">
                    <option disabled value="">--select--</option>
                    <option v-for="condition in conditionOptions" :key="condition.id"
                        :value="condition.value">{{condition.text}}</option>
                </select>
                <div v-for="(item, index) in v$.data.condition.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-md-6">
                <label for="inputColor">
                    Color <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.color"
                    :class="{ 'is-invalid': v$.data.color.$error }"
                    id="inputColor" name="color" class="form-control"
                    placeholder="Enter color" />
                <div v-for="(item, index) in v$.data.color.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="inputQuantity">
                    Quantity in Stock <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.stock_quantity" class="form-control"
                name="stock_quantity" id="inputQuantity" :class="{ 'is-invalid': v$.data.stock_quantity.$error }" placeholder="Enter Price" />
                <div v-for="(item, index) in v$.data.stock_quantity.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div v-if="editMode" class="col-6 col-md-4">
                <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                    Active
                </b-form-checkbox>
            </div> 
            <div class="col-12">
                <div class="mt-2">
                    <vue-dropzone id="productUploadDrop" ref="productUploadDrop"
                    :use-custom-slot="true" :maxFiles="1">
                    <div class="needsclick m-0">
                        <span class="bx bx-cloud-upload fs-1"></span>
                        <h6 class="mb-1"> {{editMode ? 'Add file to update':'Product image'}}</h6>
                        <span class="text-muted fs-13">
                            (Image file is recommended (500x500 pixels))
                        </span>
                    </div>
                    </vue-dropzone>
                </div>
            </div>        
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VueDropzone from "@/components/VueDropzone.vue";

const validateSlug = (value) => {
    if (!value) return true; 
    return /^\w+(?:-\w+)*$/.test(value);
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        IsLoading,
        VueDropzone
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            isLoading: false,
            data: {
                title:"",
                category_id: "",
                brand_id: "",
                slug:"",
                price: "",
                is_active: true,
                color: "",
                storage: "",
                condition: "",
                stock_quantity: 1,
            },
            storageOptions: [
                { value: "16GB", text: "16GB" },
                { value: "32GB", text: "32GB" },
                { value: "64GB", text: "64GB" },
                { value: "128GB", text: "128GB" },
                { value: "256GB", text: "256GB" },
                { value: "512GB", text: "512GB" },
                { value: "1TB", text: "1TB" }
            ],
            conditionOptions: [
                { value: "Brand New", text: "Brand New" },
                { value: "Used - Good", text: "Used - Good" },
                { value: "Used - Fair", text: "Used - Fair" },
                { value: "Refurbished", text: "Refurbished" },
                { value: "Broken", text: "Broken" }
            ],
            category: null
        }
    },
    watch: {
        'data.title': function (title){
            if(this.editMode) return;
            this.data.slug = this.$filters.slug(title)
        },
    },
    validations() {
        return {        
            data:{
                title: { required: helpers.withMessage("Title is required", required)},            
                price: { required: helpers.withMessage("Price is required", required) },                   
                slug: {
                    required,
                    validateSlug: helpers.withMessage("Enter a valid slug format (alpha-numeric)", validateSlug),
                },  
                category_id: {
                    required: helpers.withMessage("The category is required", required),
                },
                brand_id: {
                    required: helpers.withMessage("Make is required", required),
                },
                storage: { required: helpers.withMessage("Storage is required", required) },                   
                condition: { required: helpers.withMessage("Condition is required", required) },                   
                color: { required: helpers.withMessage("Color is required", required) }, 
                stock_quantity: { required: helpers.withMessage("Stock quantity is required", required) },                 
            }
        }
    },
    computed:{
        categories(){
            return this.$store.state.category.list
        },
        brands(){
            return this.categories.reduce((acc, type) => {
                if (type.id === this.data.category_id) {
                    return acc.concat(type.brands);
                }
                return acc;
            }, []);
        },
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                if(!this.collectFiles().length) {  this.alertError('Image file is required'); return  }
                const formData = new FormData()
                formData.append('image_file', this.collectFiles()[0])
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/products/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("product/ADD_PRODUCT", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/products/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data)
                            // this.$store.commit("product/UPDATE_PRODUCT", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        categoryChange(e){
            this.data.brand_id = this.data.annual_coverage_id = this.data.monthly_coverage_id = '';
            this.fetchCategory()
        },
        fetchCategory(){
            this.isLoading = true;
            this.$http.get(`/categories/${this.data.category_id}/show`)
            .then((response) => {
                this.isLoading = false;
                if(response.data.success){
                    this.category = response.data.data
                }
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.productUploadDrop){
                arrafile = [...this.$refs.productUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch("category/fetchItems")
            .then(response => this.isLoading = false );
        },

    },
    mounted(){
        this.getOptionData()
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            this.fetchCategory()
        }
    }
}
</script>
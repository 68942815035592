<template>
  <div>
    <div v-if="product" class="row">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item" v-for="(portfolio, index) in portfolios" :key="index" >
            <div class="d-flex align-items-center">
              <div class="mb-2 mb-md-0 me-3" @click="() => showImg(index)" >
                <span class="d-inline-block position-relative">
                  <span v-if="portfolio.is_video" class="video-icon-dp">
                    <i class="ri-play-circle-line"></i>
                  </span>
                  <b-img thumbnail class="cursor-pointer" fluid :src="absoluteUrl(portfolio.thumb_path)" alt="portfolios-img"></b-img>
                </span>
              </div>
              <div class="flex-grow-1">
                <span class="d-flex justify-content-between">
                  <span class="text-secondary font-sm image-name me-2">{{ portfolio.name }}</span>
                  <span>
                    <div class="dropdown no-arrow">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <button  @click.prevent="deleteItem(portfolio.id)" class="dropdown-item">
                          <i class="ri ri-delete-bin-line align-bottom me-1"></i> Delete </button>
                        </li>
                      </ul>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </li>
        </ul>
        
        <div class="d-flex justify-content-end">
          <b-button variant="primary" class="mt-2" @click.prevent="uploadModalShow = true">Add Portfolios</b-button>
        </div>
      </div>
    </div>

    <b-modal v-model="uploadModalShow" hide-footer centered
      title="Add Product Portfolios" title-class="font-18">
      <product-file-uploader v-if="uploadModalShow"  
        @addedFiles="updateFiles($event)"
        :productId="product.id" @closeMe="initializeAll()" />
    </b-modal>

    <vue-easy-lightbox v-if="product" :visible="lightbox.visible" :imgs="lightboxFiles"
        :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
  </div>
</template>

<script>
import ProductFileUploader from '@/components/forms/ProductFileUploader.vue';
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    ProductFileUploader,
    VueEasyLightbox
  },
  props:{
    product:{
      type: Object,
      default: null
    }
  },
  data() {
    return {
      uploadModalShow: false,
      pagination:{
        currentPage: 1,
        perPage: 10,
        limit:4
      },
      lightbox:{
        index: null,
        visible:false,
      },
    }
  },
  computed: {
    portfolios(){
      return this.product.files
    },
    lightboxFiles(){
      return this.portfolios.map(file => {
        if(file.is_video){
          return {
            title: file.name,
            thumb: this.absoluteUrl(file.path),
            mediaType: 'video',
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.name,
            mediaType: 'image',
            src: this.absoluteUrl(file.path) }
        }
      })
    },
  },
  methods: {
    showImg(index) {
      this.lightbox = { index: index, visible: true};
    },
    updateFiles(files){
      this.$emit('updatedFiles', files)
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this image!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/product-files/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              let files = [...this.portfolios];
              const ItemIndex = files.findIndex((item) => item.id == itemId)
              files.splice(ItemIndex, 1);
              this.updateFiles(files)
            }
          })
        }
      });
    },
    initializeAll(){
      this.uploadModalShow = false;
      this.lightbox = { index: null, visible:false}
    }
  },
}
</script>

<style scoped>

@media(max-width: 500px){
  .font-sm.image-name{
    font-size: 10px !important;
  }
}

.video-icon-dp{
  height: 25px;
  width: 25px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  line-height: 1.6;
  color: #fff;
  position: absolute;
  background: var(--bs-primary);
  cursor: pointer;
  text-align: center;
  transition: all 0.24s ease-in;
}

.video-icon-dp:hover{
  background: #000;
  color: #fff;
}
</style>

<template>
    <form autocomplete="off" class="product-description-form">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row gy-3">
            <div class="col-12">
                <label class="form-label" for="input_meta_title">Meta title</label>
                <input type="text" v-model="data.meta_title" id="input_meta_title" name="meta_title" class="form-control" placeholder="Meta title" />
            </div>
            <div class="col-12">
                <label class="form-label" for="input_meta_keys">Meta keys</label>
                <input type="text" v-model="data.meta_keys" id="input_meta_keys" name="meta-keys" class="form-control" placeholder="SEO search keywords" />
            </div>
            <div class="col-12">
                <label class="form-label" for="meta_desc">Meta description</label>
                <textarea id="meta_desc" v-model="data.meta_desc" class="form-control" rows="3" placeholder="Meta description" name="meta-desc"></textarea>
            </div>
            <div class="col-12">
                <label class="form-label" for="main_description">Main description</label>
                <div class="ckeditor-classic">
                    <ckeditor v-model="data.description" :editor="editor" :config="config"></ckeditor>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="update()"
                     type="button"> Save Changes </b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        IsLoading,
        ckeditor: CKEditor.component
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            isLoading: false,
            data: {
                meta_title: "",
                meta_keys: "",
                meta_desc: "",
                description: ""
            },
            editor: ClassicEditor,
            config:{
                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                heading: {
                    options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
                    ]
                }
            }
        }
    },
    validations() {
        return {        
            data:{}
        }
    },
    methods:{
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/products/${this.editItem.id}/update-metas`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        },
    },
    mounted(){
        this.data = {
            meta_title: this.editItem.meta_title,
            meta_keys: this.editItem.meta_keys,
            meta_desc: this.editItem.meta_desc,
            description: this.editItem.description || ""
        }
    }
}
</script>

<style>
.product-description-form .ck-content.ck-editor__editable{
    min-height: 200px;
}   
</style>
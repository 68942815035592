<template>
    <form autocomplete="off" class="">
      <vue-dropzone id="portfolioUploadDrop" ref="portfolioUploadDrop"
      :use-custom-slot="true" :maxFiles="20" :maxFilesize="800" >
        <div class="needsclick m-0">
            <span class="bx bx-cloud-upload fs-1"></span>
            <h6> Drop or Click to add files </h6>
            <span class="text-muted fs-13">
                (Should not be more than 10 files)
            </span>
        </div>
      </vue-dropzone>
      <div class="d-flex mt-3 justify-content-end">
          <b-button variant="dark" 
          @click.prevent="upload()"
            type="button">Upload</b-button>
      </div>
    </form>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";

export default {
  components:{
      VueDropzone,
  },
  props:{
    productId:{
      type: Number,
      default: null
    },
  },
  methods:{
    upload() {
      if(!this.collectFiles().length){ this.alertError("Portfolio image is required"); return;}
      let formData = new FormData();
      this.collectFiles().forEach((item, index) => formData.append('image_'+index, item))
      formData.append('product_id', this.productId)
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post('/product-files/create', formData)
      .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('addedFiles', response.data.data)
            this.resetForm();
          }
      })
    },
    collectFiles(){
      let arrafile = [];
      if(this.$refs.portfolioUploadDrop){
          arrafile = [...this.$refs.portfolioUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetForm(){
      this.$refs.portfolioUploadDrop.removeAllFiles();
      this.$emit("closeMe")
    },
  }
}
</script>